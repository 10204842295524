import React from 'react'
import Layout from '../components/Layout'
import Title from "../components/Title"
import styles from "../css/service.module.css"
import SEO from "../components/SEO"
import { Icon } from 'antd';
const servicios = () => {
    
    return (
        <div>
            <Layout>
            <SEO titlePage="AutoQL" />
            <section className={styles.services}>
            <Title title="AutoQL" subtitle="" />
            </section>
            </Layout>
        </div>
    )
}
export default servicios
